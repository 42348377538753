.App {
    background-color: #eaeded;
    width: 100%;
    height: 100%;
    /*position: fixed;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centered {
    margin-left: 50%;
    width: 100vh; /* Magic here */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.white-text-centered {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: calc(10px + 2vmin);
}

.white-text {
    color: rgb(0, 0, 0);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-link {
    color: #000000;
    text-decoration: none;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
